import React, { useContext } from 'react';
import { PersonalizationStateContext } from '../../../contentful/content-types/personalization/personalization.context';
import { UserFeatures } from '../../../contentful/content-types/personalization';
import { Button, Col, Container, Div, H5, Input, Label, Row, Span } from '../Elements';
import { Icon } from '../Icon';
import Text from '../Text/Text';
import { userFeaturesDefault } from '../../../contentful/content-types/personalization/personalization.interface';

type FactField = {
  [x in keyof UserFeatures]: {
    label: string;
    type: 'text' | 'checkbox' | 'number';
    notFormElement?: boolean;
  };
};

const factFields: FactField = {
  is_customer: {
    label: 'Is customer',
    type: 'checkbox',
  },
  bot_management_enabled: {
    label: 'Bot Management enabled',
    type: 'checkbox',
  },
  argo_allow_smart_routing: {
    label: 'Argo allow smart routing',
    type: 'checkbox',
  },
  has_free_zone: {
    label: 'Has Free Zone',
    type: 'checkbox',
  },
  has_pro_zone: {
    label: 'Has Pro Zone',
    type: 'checkbox',
  },
  has_biz_zone: {
    label: 'Has Biz Zone',
    type: 'checkbox',
  },
  has_ent_zone: {
    label: 'Has Ent Zone',
    type: 'checkbox',
  },
  ctm_enabled: {
    label: 'CTM Enabled',
    type: 'checkbox',
  },
  workers_enabled: {
    label: 'Workers Enabled',
    type: 'checkbox',
  },
  audit_logs_enabled: {
    label: 'Audit Logs Enabled',
    type: 'checkbox',
  },
  cf_registrar_enabled: {
    label: 'CF Registrar Enabled',
    type: 'checkbox',
  },
  proxy_anything_enabled: {
    label: 'Proxy Anything Zone',
    type: 'checkbox',
  },
  access_enabled: {
    label: 'Access Enabled',
    type: 'checkbox',
  },
  rate_limiting_enabled: {
    label: 'Rate limiting Enabled',
    type: 'checkbox',
  },
  stream_enabled: {
    label: 'Stream Enabled',
    type: 'checkbox',
  },
  logshare_enabled: {
    label: 'Logshare Enabled',
    type: 'checkbox',
  },
  organization_name: {
    label: 'Organization Name',
    type: 'text',
  },
  pct_avg_change: {
    label: 'Percentage Average Change',
    type: 'number',
  },
  argo_avg: {
    label: 'Argo Average',
    type: 'number',
  },
  no_argo_avg: {
    label: 'No Argo Average Average',
    type: 'number',
  },
};

const PreviewWindow: React.FC<{}> = ({}) => {
  const handleChange = (e: any, isCheckbox: boolean) => {
    setPreviewData({
      ...((previewData as any) || {}),
      [e.target.name as any]: isCheckbox ? e.target.checked : e.target.value,
    });
  };
  const { isPreview, setIsPreview, previewData, setPreviewData } = useContext(
    PersonalizationStateContext
  );

  return (
    <Container>
      <Div
        backgroundColor="white"
        position="fixed"
        style={{
          right: isPreview ? 0 : '-400px',
          top: '100px',
          width: '400px',
          height: `calc(100% - 100px)`,
          transition: ' 0.3s right ease-in-out',
          boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
          zIndex: 6,
          overflowY: 'scroll',
        }}
      >
        <Div
          display="flex"
          justifyContent="between"
          alignItems="center"
          paddingVertical={2}
          backgroundColor="gray9"
          border="bottom"
          borderColor="gray7"
          paddingHorizontal={3}
        >
          <Span fontWeight="bold">Personalizer Component</Span>
          <Button
            backgroundColor="transparent"
            onClick={() => {
              setIsPreview(false);
              setPreviewData(userFeaturesDefault);
            }}
          >
            <Icon type="markX" fill="gray1" />
          </Button>
        </Div>

        <Div paddingHorizontal={3}>
          {Object.keys(factFields).map(fact => {
            const isCheckBox = (factFields as any)[fact].type === 'checkbox';
            return (
              <Div
                border="top"
                justifyContent="between"
                alignItems="center"
                borderColor="gray7"
                key={fact}
                display={(factFields as any)[fact].type === 'checkbox' ? 'flex' : 'block'}
              >
                {(factFields as any)[fact].type === 'checkbox' && (
                  <Label display="flex" flexWrap="wrap-reverse">
                    <Text tag="Span" variant="body2-bold">
                      {(factFields as any)[fact].label}
                    </Text>
                  </Label>
                )}
                <Div marginVertical={1}>
                  {(factFields as any)[fact].type !== 'checkbox' && (
                    <Label display="flex" flexWrap="wrap-reverse" marginTop={2} marginBottom={1}>
                      <Text tag="Span" variant="body2-bold">
                        {(factFields as any)[fact].label}
                      </Text>
                    </Label>
                  )}
                  <Input
                    name={fact}
                    fontSize={2}
                    onChange={e => handleChange(e, isCheckBox)}
                    padding={isCheckBox ? 0 : 1}
                    width={isCheckBox ? 'auto' : '100%'}
                    placeholder={(factFields as any)[fact].label}
                    marginRight={1}
                    type={(factFields as any)[fact].type}
                    className="marketo-input"
                  ></Input>
                </Div>
              </Div>
            );
          })}
        </Div>
      </Div>
    </Container>
  );
};

export default PreviewWindow;
